import React, { Component } from 'react';
import axios from 'axios';

// Import Utils
import isMobile from '../../../utils/isMobile';

import Dnd from '../../Dnd/Dnd';
import Loading from '../../Loading/Loading';
import Notification from '../../Notification/Notification';

import './Form.scss';

class Form extends Component {
    state = {
        endPoint: (window.location.host.indexOf("estagiorealoficial.com.br") !== -1) ? 'http://estagiorealoficial-back.isobarhom.com.br/cadastro/salvar' : 'http://estagiorealoficial-back.isobardev.com.br/cadastro/salvar',
        // endPoint: 'http://estagiorealoficial-back.isobardev.com.br/cadastro/salvar',
        // endPoint: 'http://10.81.7.57:8080/cadastro/salvar',
        isMobile: isMobile(),
        preform: {
            level: this.props.preform ? this.props.preform.level : null
        },
        isLoading: false,
        notification: null
    }

    formRef = React.createRef();

    // eslint-disable-next-line
    currentYear = (new Date).getFullYear();

    // Handle files return from Dnd component
    handleFiles = (files) => {
        let file;

        if (!files[0].name) return;
        file = files[0];

        const reader = new FileReader();
        reader.addEventListener('load', () => {
            let ext = file.type.match(/\w.*\/(.*)$/)[1]

            switch (ext) {
                case 'msword':
                    ext = 'doc'
                    break;
                case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
                    ext = 'docx'
                    break;
                default:
                    break;
            }

            if (file.size > 4000000) {
                this.handleNotification({
                    "title": "Erro",
                    "info": "O tamanho máximo para um arquivo é de 4mb.",
                    "agree": null,
                    "error": true
                })
                return;
            }

            if (['doc', 'docx', 'pdf'].indexOf(ext) === -1) {
                this.handleNotification({
                    "title": "Erro",
                    "info": "Só são aceitos arquivos nos formatos DOC, DOCX e PDF.",
                    "agree": null,
                    "error": true
                })
                return;
            }

            this.setState({file: {
                case: file.name,
                stream: reader.result,
                ext: ext === 'x-zip-compressed' ? 'zip' : ext
            }});
        })

        reader.readAsDataURL(file);
    }

    // Handle resize
    resizeHandler() {
        // Check if it is a mobile viewport...
        // ... if it is diferent than the last execution...
        if (isMobile() !== this.state.isMobile) {
            // ... and reset state with new data if changed.
            this.setState({ isMobile: isMobile() });
        }
    }

    // Handle Form mask
    formMaskHandler(event, id) {
        const target = event.target;
        const type = event.target.dataset.type;

        const CustomFieldsMasks = {
            phone: (value) => {
                value = value.replace(/\D/g, "")                        //Remove tudo o que não é dígito
                value = value.replace(/^(\d{2})/g, "($1) ")             //Coloca parênteses em volta dos dois primeiros dígitos
                value = value.replace(/(\d{4,5})(\d{4})$/, "$1-$2")     //Coloca hífen entre o quarto e o quinto dígitos
                return value
            },
            birth: (value) => {
                value = value.replace(/\D/g, "")                            //Remove tudo o que não é dígito
                value = value.replace(/^(\d{2})(\d{2})(\d)/, "$1/$2/$3")    //Coloca barra após finalizar
                value = value.replace(/^(\d{2})(\d)/, "$1/$2")              //Coloca barra após primeiros dois digitos
                return value
            },
            finish: (value) => {
                value = value.replace(/\D/g, "")                            //Remove tudo o que não é dígito
                value = value.replace(/^(\d{2})(\d)/, "$1/$2")              //Coloca barra após primeiros dois digitos
                return value
            }
        }

        if (target) {
            target.value = CustomFieldsMasks[type](target.value);
        }
    }

    // Handle max area options
    maxAreaHandler() {
        const checked = document.querySelectorAll('[name=form--checkbox__areas]:checked');
        const unchecked = document.querySelectorAll('[name=form--checkbox__areas]:not(:checked)');

        for (let i = 0; i < unchecked.length; i++) {
            unchecked[i].disabled = (checked.length >= 3);
        }
    }

    // Handle submit event
    submitHandler = (event) => {
        // Prevent submit
        event.preventDefault();
        event.stopPropagation();

        // Start loading
        this.setState({isLoading: true});

        // Get form reference
        const formEl = event.currentTarget;

        // Initialize data object
        let formData = {
            nome: null,
            dt_nascimento: null,
            telefone: null,
            dt_conclusao: null,
            nivel_ingles: null,
            areas: [],
            link: null,
            file_case: null,
            file_stream: null,
            file_ext: null,
            tipo_estudante: null
        };

        // Get data from the form
        formData.nome = typeof formEl.querySelector('#name').value === 'string' && formEl.querySelector('#name').value.length > 0 ? formEl.querySelector('#name').value : null;
        formData.dt_nascimento = typeof formEl.querySelector('#birth').value === 'string' && formEl.querySelector('#birth').value.length > 0 ? formEl.querySelector('#birth').value.split('/').reverse().join('-') : null;
        formData.telefone = typeof formEl.querySelector('#phone').value === 'string' && formEl.querySelector('#phone').value.length > 0 ? formEl.querySelector('#phone').value : null;
        formData.dt_conclusao = typeof formEl.querySelector('#finish').value === 'string' && formEl.querySelector('#finish').value.length > 0 ? formEl.querySelector('#finish').value.split('/').reverse().join('-') + '-01' : null;

        formData.nivel_ingles = formEl.querySelector('[name=form--radio__english]:checked').value;

        formEl.querySelectorAll('[name=form--checkbox__areas]:checked').forEach((element) => {
            formData.areas.push(element.value);
        })

        formData.tipo_estudante = this.state.preform.level;

        if (formEl.querySelector('[name=form--radio__resume]:checked').value === 'file' && typeof this.state.file === 'object') {
            formData.file_case= this.state.file.case
            formData.file_stream= this.state.file.stream
            formData.file_ext= this.state.file.ext
        } else if (formEl.querySelector('[name=form--radio__resume]:checked').value === 'link') {
            formData.link = typeof formEl.querySelector('#form--resume-link').value === 'string' && formEl.querySelector('#form--resume-link').value.length > 0 ? formEl.querySelector('#form--resume-link').value : null;
        }

        // Validate data
        if (
            formData.nome &&
            formData.dt_nascimento &&
            formData.telefone &&
            formData.dt_conclusao &&
            formData.nivel_ingles &&
            formData.areas.length &&
            (formData.link || (formData.file_case && formData.file_stream && formData.file_ext))
        ) {

            let fd = new FormData();

            fd.append('nome', formData.nome);
            fd.append('dt_nascimento', formData.dt_nascimento);
            fd.append('telefone', formData.telefone);
            fd.append('dt_conclusao', formData.dt_conclusao);
            fd.append('nivel_ingles', formData.nivel_ingles);
            fd.append('areas', formData.areas);
            fd.append('link', formData.link);
            fd.append('tipo_estudante', formData.tipo_estudante);
            fd.append('file_case', formData.file_case);
            fd.append('file_stream', formData.file_stream);
            fd.append('file_ext', formData.file_ext);

            // formData = JSON.stringify(formData);
            formData = fd;

            axios({
                method: 'post',
                url: this.state.endPoint,
                data: formData,
                config: { headers: { 'Content-Type': 'application/json' } }
            })
            .then((res) => {
                // Stop loading
                this.setState({ isLoading: false });
                if (res.data.success) {
                    this.props.goto(2);
                } else {
                    this.handleNotification({
                        "title": "Erro",
                        "info": "Ocorreu um erro ao enviar seu cadastro, tente novamente.",
                        "agree": null,
                        "error": true
                    })
                }
            })
            .catch((err) => {
                // Stop loading
                this.setState({ isLoading: false });

                this.handleNotification({
                    "title": "Erro",
                    "info": "Ocorreu um erro ao enviar seu cadastro, tente novamente.",
                    "agree": null,
                    "error": true
                })
            })
        } else {
            // Stop loading
            this.setState({ isLoading: false });

            this.handleNotification({
                "title": "Erro",
                "info": "Todos os campos são obrigatórios.",
                "agree": null,
                "error": true
            })
        }

    }

    // Handle the error notifications
    handleNotification = (notification) => {
        this.setState({ notification })
    }

    componentDidMount() {
        // Handle resize event to retest viewport
        window.addEventListener("resize", this.resizeHandler.bind(this));
    }

    componentWillUnmount(props) {
        // Remove resize event
        window.removeEventListener("resize", this.resizeHandler)
    }

    render() {
        let { notification } = this.state;

        return (
            <React.Fragment>
                <section id="form" data-selector="form">
                    {this.state.isLoading && <Loading />}
                    <form ref={this.formRef} className="selector" onSubmit={this.submitHandler}>
                        <fieldset className="form--fieldset form--fieldset__basic">
                            <label className="form--label">
                                {this.props.data.name}
                                <input id="name" type="text" className="form--input form--input__text" />
                            </label>
                            <label className="form--label">
                                {this.props.data.birth}
                                <input id="birth" type="text" className="form--input form--input__text" data-type="birth" placeholder={`01/01/${(this.currentYear - 18)}`} maxLength="10" onChange={this.formMaskHandler} />
                            </label>
                            <label className="form--label">
                                {this.props.data.phone}
                                <input id="phone" type="text" className="form--input form--input__text" data-type="phone" maxLength="15" onChange={this.formMaskHandler} />
                            </label>
                            <label className="form--label">
                                {this.props.data.finish}
                                <input id="finish" type="text" className="form--input form--input__text" data-type="finish" placeholder={`12/${this.currentYear}`} maxLength="7" onChange={this.formMaskHandler} />
                            </label>
                        </fieldset>
                        <fieldset className="form--fieldset form--fieldset__english">
                            <legend>{this.props.data.level.title}</legend>
                            <input value="basic" type="radio" id="form--radio__basic" name="form--radio__english" className="form--radio form--radio__english" defaultChecked />
                            <label className="form--label form--label__basic" htmlFor="form--radio__basic">
                                {this.props.data.level.basic}
                            </label>
                            <input value="intermediate" type="radio" id="form--radio__intermediate" name="form--radio__english" className="form--radio form--radio__english" />
                            <label className="form--label form--label__intermediate" htmlFor="form--radio__intermediate">
                                {this.props.data.level.intermediate}
                            </label>
                            <input value="advanced" type="radio" id="form--radio__advanced" name="form--radio__english" className="form--radio form--radio__english" />
                            <label className="form--label form--label__advanced" htmlFor="form--radio__advanced">
                                {this.props.data.level.advanced}
                            </label>
                        </fieldset>
                        <hr />
                        <fieldset className="form--fieldset form--fieldset__areas">
                            <legend>{this.props.data.areas.title}</legend>
                            {this.props.data.areas.options.map((inputData, index) => (
                                <React.Fragment key={`${inputData.value}-${index}`}>
                                    <input value={inputData.value} onChange={this.maxAreaHandler} type="checkbox" id={`form--checkbox__${inputData.value}`} name="form--checkbox__areas" className="form--checkbox form--checkbox__areas" />
                                    <label className="form--label" htmlFor={`form--checkbox__${inputData.value}`}>
                                        {inputData.text}
                                    </label>
                                </React.Fragment>
                            ))}
                        </fieldset>
                        <fieldset className="form--fieldset form--fieldset__resume">
                            <legend>{this.props.data.resume.title}</legend>
                            <input value="file" type="radio" id="form--radio__resume-file" name="form--radio__resume" className="form--radio form--radio__resume form--radio__resume-file" />
                            <label className="form--label-tab form--label-tab__file" htmlFor="form--radio__resume-file">
                                {this.props.data.resume.file.tab}
                            </label>
                            <Dnd className="form--resume-file" handleFiles={this.handleFiles} dropHere={this.props.data.resume.file.text.drop} loadText="Obrigado!">
                                <div className="form--resume-file__container">
                                    {isMobile() ? this.props.data.resume.file.text.mobile : this.props.data.resume.file.text.desktop}
                                </div>
                            </Dnd>
                            <input value="link" type="radio" id="form--radio__resume-link" name="form--radio__resume" className="form--radio form--radio__resume form--radio__resume-file" defaultChecked />
                            <label className="form--label-tab form--label-tab__link" htmlFor="form--radio__resume-link">
                                {this.props.data.resume.link.tab}
                            </label>
                            <input type="text" name="form--resume-link" id="form--resume-link" className="form--input form--input__text form--resume-link" placeholder={this.props.data.resume.link.text} />
                        </fieldset>
                        <input type="submit" value={this.props.data.send} className="form--input form--input__submit" />
                    </form>
                </section>
                {notification && <Notification {...notification} handle={this.handleNotification} />}
            </React.Fragment>
        )
    }
}

export default Form;
