import React from 'react';

// Import components
import Anchor from '../../Anchor/Anchor';

// Import SCSS
import './Hero.scss';

// Import Utils
import isMobile from '../../../utils/isMobile';

// Import SVG arrow icon
import { ReactComponent as Arrow } from '../../../svg/down-arrow.svg';

const Hero = (props) => {
    // If it is mobile, create the button to the form
    const registerBtn = isMobile() ? <Anchor href={props.data.register.anchor} className="hero--register">{props.data.register.cta}</Anchor> : null;

    return (
        <section id="hero" className="selector" data-selector="hero">
            <h1 className="hero--title">{ props.data.title.map((line, index) => (<span key={index}>{line}</span>)) }</h1>
            {registerBtn}
            <Anchor href={props.data.discover.anchor} className="hero--anchor"><Arrow />{isMobile() ? '' : props.data.discover.cta}</Anchor>
        </section>
    )
};

export default Hero;
