import React from 'react';

// Import SVGs
import Amnet from '../../svg/brands/logo-amnet.svg';
import Cosin from '../../svg/brands/logo-cosin.svg';
import Dentsu from '../../svg/brands/logo-dentsu.svg';
import DentsuMGB from '../../svg/brands/logo-dentsumgb.svg';
import Iprospect from '../../svg/brands/logo-iprospect.svg';
import Isobar from '../../svg/brands/logo-isobar.svg';
import Lov from '../../svg/brands/logo-lov.svg';
import Mcgarry from '../../svg/brands/logo-mcgarry.svg';
import Mktg from '../../svg/brands/logo-mktg.svg';
import Nbs from '../../svg/brands/logo-nbs.svg';
import Pontomobi from '../../svg/brands/logo-pontomobi.svg';
import Posterscope from '../../svg/brands/logo-posterscope.svg';
import Storylab from '../../svg/brands/logo-storylab.svg';

const Brand = (props) => {
    // Initialize brand variable;
    let chosenBrand = null;
    const slug = props.slug;

    switch (slug) {
        case "amnet":
            chosenBrand = <img src={Amnet} alt={props.name} className={props.slug} />;
            break;
        case "cosin":
            chosenBrand = <img src={Cosin} alt={props.name} className={props.slug} />;
            break;
        case "dentsu":
            chosenBrand = <img src={Dentsu} alt={props.name} className={props.slug} />;
            break;
        case "dentsumgb":
            chosenBrand = <img src={DentsuMGB} alt={props.name} className={props.slug} />;
            break;
        case "iprospect":
            chosenBrand = <img src={Iprospect} alt={props.name} className={props.slug} />;
            break;
        case "isobar":
            chosenBrand = <img src={Isobar} alt={props.name} className={props.slug} />;
            break;
        case "lov":
            chosenBrand = <img src={Lov} alt={props.name} className={props.slug} />;
            break;
        case "mcgarry":
            chosenBrand = <img src={Mcgarry} alt={props.name} className={props.slug} />;
            break;
        case "mktg":
            chosenBrand = <img src={Mktg} alt={props.name} className={props.slug} />;
            break;
        case "nbs":
            chosenBrand = <img src={Nbs} alt={props.name} className={props.slug} />;
            break;
        case "pontomobi":
            chosenBrand = <img src={Pontomobi} alt={props.name} className={props.slug} />;
            break;
        case "posterscope":
            chosenBrand = <img src={Posterscope} alt={props.name} className={props.slug} />;
            break;
        case "storylab":
            chosenBrand = <img src={Storylab} alt={props.name} className={props.slug} />;
            break;
        default:
            break;
    }

    return (
        <React.Fragment>
            {chosenBrand}
        </React.Fragment>
    )
}

export default Brand;
