import React, { Component } from 'react';
import scrollTo from '../../utils/scrollTo'

class Anchor extends Component {
    
    anchorHandler(ev) {
        ev.preventDefault()
        let id, el, y;

        id = ev.currentTarget.href
        if(!id) return 
        
        id = id.replace(/.*#(.*)$/, "$1")
        el = document.getElementById(id);
        if(!el) return 

        y = el.offsetTop

        return scrollTo(y, 150);
    }
    
    render() {
        return (
            <a href={this.props.href} style={{cursor: "pointer"}} data-anchor className={this.props.className} onClick={this.anchorHandler}>{this.props.children}</a>
        );
    }
}

export default Anchor;
