import React, { Component } from 'react';

import './Feedback.scss';

import data from '../../../data/Feedback';

class Feedback extends Component {

	constructor(props) {
		super(props);

		this.state = {}
	}

	shareData(uri, type) {
        if( type === "copy" )  {
            const el = document.createElement('textarea');
            el.value = uri;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
        }

        if( type === "social" )  {
            let w = window.innerWidth/3;
            let h = window.innerHeight/2;
            let LeftPosition = (window.screen.width) ? (window.screen.width-w)/2 : 0;
            let TopPosition = (window.screen.height) ? (window.screen.height-h)/2 : 0;
            let settings = 'height='+h+',width='+w+',top='+TopPosition+',left='+LeftPosition+',resizable'

            window.open(uri, null , settings);
        }
	}

	render() {
		return (
			<section id="feedback" className="selector" data-selector="feedback">
				<div className="feedback_title">{data.title}</div>
				<div className="feedback_subtitle">{data.subtitle}</div>
				<div className="feedback_share">
					<div className="share_text">{data.share}</div>
					<div className="share-icons_wrap">

						{data.links.map((media, index) => {
							return(
								<div key={index} className={`share_item ${media.slug}`}>
									<button className={`${media.slug}_ico`} onClick={() => this.shareData(media.link, media.type)} data-href={media.link}/>
								</div>
							)
						})}

					</div>
				</div>

			</section>
		);
	}
}

export default Feedback;
