import React from 'react';

// Navigation and footer are commont to all the pages
import Navigation from '../Navigation/Navigation';
import Footer from '../Footer/Footer';

const layout = (props) => (
    <React.Fragment>
        {props.children}
        <Navigation />       
        <Footer />
    </React.Fragment>
);

export default layout;
