import React, { Component } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Layout from './components/Layout/Layout';

// Import Utils
import isMobile from './utils/isMobile';

// Import pages
import Home from './pages/Home/Home';


const browserHistory = createBrowserHistory();

class App extends Component {
    state = {
        isMobile: isMobile() // Set if it's mobile viewport on load
    }

    setVHUnit() {
        // Get viewport height unit
        const vh = window.innerHeight * .01;

        // Set CSS custom property for the value on the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    componentDidMount() {
        this.setVHUnit();

        window.addEventListener('resize', () => {
            this.setVHUnit();
        })
    }

    render() {
        const Page = (props) => (
            <Layout isMobile={this.state.isMobile}>
                <Home isMobile={this.state.isMobile} match={props.match}/>
            </Layout>
        );

        return (
            <Router history={browserHistory}>
                <Switch>
                    <Route path="/politica-de-privacidade" component={Page} exact/>
                    <Route path="/" component={Page} exact/>
                </Switch>
            </Router>
        );
    }
}

export default App;
