import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './BackStep.scss';

class BackStep extends Component {

	render() {
		return (
			<NavLink className="backstep_btn" to="/" onClick={() => this.props.goBack(0)} />
		);
	}
}

export default BackStep;
