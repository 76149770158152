import React, { Component } from 'react';

import './Corona.scss';

class Corona extends Component {
    state = {
        isOpen: true,
    }

    closeHandler = () => {
        this.setState({isOpen: false});
    }

    render() {
        let content;
        const isOpen = this.state.isOpen;

        if (isOpen) {
            content = (
                <div className="corona" id="Corona">
                    <button onClick={this.closeHandler}>Fechar</button>
                    {this.props.data.text.map((text, index) => (index < 1 ? <h4 key={`corona-${index}`}>{text}</h4> : <p key={`corona-${index}`}>{text}</p>))}
                </div>
            );
        } else {
            content = null;
        }

        return (
            <React.Fragment>
                {content}
            </React.Fragment>
        )
    }
}

export default Corona;
