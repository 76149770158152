import React from 'react';

import Data from '../../data/PrivacyPolicy';

import { ReactComponent as Arrow } from '../../svg/down-arrow.svg';

import './PrivacyPolicy.scss';

// Import Utils
import isMobile from '../../utils/isMobile';

const PrivacyPolicy = (props) => (
    <section id="privacy_policy" className="selector" data-selector="privacy_policy">
        <h1 className="privacy_policy--title">{Data.title}</h1>
        {!isMobile() ? <Arrow className="privacy_policy--arrow" /> : null}
        {Data.resume.map((line, i) => (<p key={`resume_${i}`} className="resume--text" dangerouslySetInnerHTML={{__html: line }}/>))}
        {Data.clauses.map((clause, i) => (
            <React.Fragment key={`clause_${i}`}>
                <hr />
                <div className="clause--box">
                    <h3 className="clause--title" dangerouslySetInnerHTML={{__html: clause.title }}/>
                    <p dangerouslySetInnerHTML={{__html: clause.text }} className="clause--text"/>
                </div>
            </React.Fragment>
        ))}
        <hr />

        {Data.conclusion.map((line, i) => (<p key={`conclusion_${i}`} className="conclusion--text" dangerouslySetInnerHTML={{__html: line }}/>))}
    </section>
);

export default PrivacyPolicy;
