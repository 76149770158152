import React, { Component } from 'react';
import './Navigation.scss';

// Import Utils
import isMobile from '../../utils/isMobile';

// Import components
import Anchor from '../Anchor/Anchor';

// Import SVGs as components
import { ReactComponent as LogoHor } from '../../svg/logo-dan-hor.svg';
import { ReactComponent as LogoVert } from '../../svg/logo-dan-vert.svg';

// Import content
import data from '../../data/Home';

class navigation extends Component {

    state = {
        isMobile: isMobile()
    }

    componentDidMount() {
        // Handle resize event to retest viewport
        window.addEventListener("resize", this.resizeHandler.bind(this));
    }

    componentWillUnmount() {
        // Remove resize event
        window.removeEventListener("resize", this.resizeHandler)
    }

    // Handle resize
    resizeHandler() {
        // Check if it is a mobile viewport...
        // ... if it is diferent than the last execution...
        if (isMobile() !== this.state.isMobile) {
            // ... and reset state with new data if changed.
            this.setState({ isMobile: isMobile() });
        }
    }

    render() {
        const Logo = this.state.isMobile ? LogoHor : LogoVert;
        const registerBtn = !this.state.isMobile ? <Anchor href={data.hero.register.anchor} style={{ cursor: "pointer" }} className="navigation--register">{data.hero.register.cta}</Anchor> : null;

        return (
            <nav className="navigation">
                <Logo />
                {registerBtn}
            </nav>
        );
    }
}



export default navigation;
